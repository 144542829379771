import { computed, PropType, defineComponent } from 'vue';
import renderFn from '../utils/render-fn';
import {
  TdIconSVGProps, SVGJson,
} from '../utils/types';
import useSizeProps from '../utils/use-size-props';

import '../style/css';

const element: SVGJson = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M2 13V8h1v2.3l3-3 5.7 5.7H13V3H8V2h5a1 1 0 011 1v10a1 1 0 01-1 1H3a1 1 0 01-1-1zm4-4.3l-3 3V13h7.3L6 8.7z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M12 6a2 2 0 11-4 0 2 2 0 014 0zm-1 0a1 1 0 10-2 0 1 1 0 002 0zM6.28 5.56l-.7.7-1.42-1.4-1.41 1.4-.71-.7 1.41-1.41-1.41-1.42.7-.7 1.42 1.4 1.41-1.4.71.7-1.41 1.42 1.41 1.4z","fillOpacity":0.9}}]};

export default defineComponent({
  name: 'ImageErrorIcon',
  props: {
    size: {
      type: String,
    },
    onClick: {
      type: Function as PropType<TdIconSVGProps['onClick']>,
    },
  },
  setup(props, { attrs }) {
    const propsSize = computed(() => props.size);

    const { className, style } = useSizeProps(propsSize);

    const finalCls = computed(() => ['t-icon', 't-icon-image-error', className.value]);
    const finalStyle = computed(() => ({ ...style.value, ...(attrs.style as Styles) }));
    const finalProps = computed(() => ({
      class: finalCls.value,
      style: finalStyle.value,
      onClick: (e:MouseEvent) => props.onClick?.({ e }),
    }));
    return () => renderFn(element, finalProps.value);
  },

});
